import styled from 'styled-components';

export const Input = styled.input`
	display: flex;
	width: 100%;
	color: #000;
	font-weight: 700;
	font-size: 1.6rem;
	height: 5.5rem;
	line-height: 5.5rem;
	text-transform: uppercase;
	font-family: 'Adieu';
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};

	::placeholder {
		color: #000;
	}
`;

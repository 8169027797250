import styled, { css } from 'styled-components';
import media from '../../constants/media';

export const Wrapper = styled.div`
	background-size: 100% auto;
	background-repeat: repeat-y;
	background-position: center top;
	background-image: url('https://images.prismic.io/platform-gatt/6a0a2412-f7cf-400f-9fa0-6514e463d845_GATT_www_artistpage_bakcground.jpg?auto=compress,format');
	background-attachment: scroll;

	${media.tabletOnly`
		[name="Column"]:nth-child(2) { margin-top: 9rem; }
		[name="Column"]:nth-child(2n + 3) { margin-top: -9rem; }
	`}

	${media.desktopSmall`
	[name="Column"]:nth-child(2) { margin-top: 18rem; }
		[name="Column"] { &:nth-child(3n + 6), &:nth-child(3n + 4) { margin-top: -18rem; } }
	`}
`;

export const ListWrapper = styled.div`
	opacity: 1;
	transition: opacity ${({ fadeOutDuration }) => fadeOutDuration}ms linear;

	${({ fadeOut }) =>
		fadeOut &&
		css`
			opacity: 0;
		`}
`;

export const FiltersWrapper = styled.div`
	padding: 0 5rem;
`;

export const NoArtistsFoundMessage = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	font-size: 1.8rem;
	padding: 3rem;

	${media.tablet`
		font-size: 3rem;
	`}
`;

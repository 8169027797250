import styled from 'styled-components';
import media from '../../../constants/media';

export const Wrapper = styled.div`
	margin-bottom: 6rem;
	margin-left: 3rem;
	margin-right: 3rem;

	${media.tablet`
		display: flex;
		margin-left: 0;
		margin-right: 0;
	`};
`;

export const FilterWrapper = styled.div`
	${media.mobileOnly`
		margin-bottom: 3rem;
	`}

	${media.tablet`
		flex: 0 0 25%;

		&:nth-child(1) {
			padding-right: 3rem;
		}

		&:nth-child(2) {
			padding-right: 3rem;
			padding-left: 3rem;
		}

		&:nth-child(3) {
			padding-right: 3rem;
			padding-left: 3rem;
		}
		&:nth-child(4) {
			padding-left: 3rem;
		}
	`};
`;

export const SearchWrapper = styled.div`
	position: relative;

	svg {
		position: absolute;
		top: 1.3rem;
		right: 0;
		pointer-events: none;
	}
`;

import { fluidRange } from 'polished';

export const fluid = (prop: string, fromSize: string, toSize: string, screenMin = '320px', screenMax = '2540px') => fluidRange(
	{
		prop: prop,
		fromSize: fromSize,
		toSize: toSize
	},
	screenMin,
	screenMax
);

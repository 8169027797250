import { graphql } from 'gatsby';
import { shuffle } from 'lodash';
import React, { FC, useContext } from 'react';
import Artists from '../components/artists';
import AppContext from '../contexts/appContext/appContext';
import IArtistListItem from '../interfaces/artistListItem/artistListItem';
import IEventListItem from '../interfaces/eventListItem/eventListItem';

interface Props {
	data: any;
}

const Index: FC<Props> = ({ data }) => {
	const { artists, setArtists } = useContext(AppContext);
	let artistList: IArtistListItem[] = [];

	// Shuffling the artists on our first session entry to the application
	// So they appear in randomized order each time
	if (artists) {
		artistList = artists;
	} else {
		artistList = data.allPrismicArtist.edges;
		setArtists(data.allPrismicArtist.edges);
	}

	const events: IEventListItem[] = data.allPrismicEvent.edges;

	return (
		<>
			<Artists artists={artistList} events={events} country="" />
		</>
	);
};

export default Index;

export const pageQuery = graphql`
	{
		allPrismicArtist(filter: { data: { name: { text: { ne: "Ignored-dummy-artist" } } } }) {
			edges {
				node {
					id
					uid
					data {
						name {
							text
						}
						main_image {
							url
							mobile {
								url
							}
							tablet {
								url
							}
							desktop_large {
								url
							}
							retina {
								url
							}
						}
						events {
							event {
								uid
							}
						}
						artforms {
							artform
						}
						country
					}
				}
			}
		}
		allPrismicEvent(filter: { data: { title: { text: { ne: "Ignored-dummy-event" } } } }) {
			edges {
				node {
					id
					uid
					data {
						title {
							text
						}
						image {
							url
						}
					}
				}
			}
		}
		prismicSettings {
			data {
				about_text {
					html
				}
				frontpage_image {
					url
				}
				frontpage_video {
					url
				}
				frontpage_hero_media
			}
		}
	}
`;

import React, { FC, useContext, useRef, useState } from 'react';
import { useInView } from 'react-hook-inview';
import * as StyledArtistTile from './artistTile.styles';
import IArtistListItem from '../../interfaces/artistListItem/artistListItem';
import { nullCheckImage } from '../../helpers/imageHelpers/nullCheckImage';
import AppContext from '../../contexts/appContext/appContext';
import { overlayColorMapper } from './overlayColorMapper';

interface Props {
	artist: IArtistListItem;
}

const ArtistTile: FC<Props> = ({ artist }) => {
	const { isAnimationFinished } = useContext(AppContext);
	const elem = useRef(null);
	const [isVisible, setIsVisible] = useState(false);

	useInView({
		target: elem,
		threshold: 0,
		onEnter: ([entry]) => setIsVisible(entry.isIntersecting),
	});

	return (
		<div ref={elem}>
			<StyledArtistTile.WrapperLink
				to={`/${artist.node.uid}`}
				mobile={nullCheckImage(artist.node.data.main_image.mobile)}
				tablet={nullCheckImage(artist.node.data.main_image.tablet)}
				desktop={nullCheckImage(artist.node.data.main_image.desktop_large)}
				retina={nullCheckImage(artist.node.data.main_image.retina)}
				defaultimg={artist.node.data.main_image.url}
				show={isAnimationFinished && isVisible ? 'yes' : 'no'}
			/>
			<StyledArtistTile.Inner>
				<StyledArtistTile.Name>{artist.node.data.name.text}</StyledArtistTile.Name>
				<StyledArtistTile.Artform>
					{artist.node.data.artforms.map((artform, index) => (
						<span key={index}>
							{index > 0 && <span>, </span>}
							<span>{artform.artform}</span>
						</span>
					))}
				</StyledArtistTile.Artform>
			</StyledArtistTile.Inner>
		</div>
	);
};

export default ArtistTile;

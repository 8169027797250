import React, { FC, useState } from 'react';
import * as StyledArtists from './artists.styles';
import { Columns, Column, Container } from '../grid/grid';
import ArtistTile from '../artistTile';
import ArtistFilters from './artistFilters';
import IArtistListItem from '../../interfaces/artistListItem/artistListItem';
import { Filters } from './artistFilters/artistFilters';
import IEventListItem from '../../interfaces/eventListItem/eventListItem';
import { getParameterByName } from '../../helpers/urlHelpers/getParameterByName';

interface Props {
	artists: IArtistListItem[];
	events: IEventListItem[];
	country: string;
}

const clone = data => JSON.parse(JSON.stringify(data));
const artistFadeOutDuration = 150;

const getFilteredArtists = (artists: IArtistListItem[], filters: Filters) => {
	let updatedArtists: IArtistListItem[] = clone(artists);
	// Name
	if (filters.name.length > 0) {
		updatedArtists = updatedArtists.filter(x =>
			x.node.data.name.text.toLowerCase().includes(filters.name.toLowerCase())
		);
	}

	if (filters.location.length > 0) {
		// Location/Event
		updatedArtists = updatedArtists.filter(
			x =>
				x.node.data.events.filter(
					y => y.event && y.event.uid.toLowerCase().includes(filters.location.toLowerCase())
				).length > 0
		);
	}

	if (filters.artform.length > 0) {
		// Artform
		updatedArtists = updatedArtists.filter(
			x =>
				x.node.data.artforms.filter(y => y.artform.toLowerCase().includes(filters.artform.toLowerCase()))
					.length > 0
		);
	}
	if (filters.country !== null) {
		// Country
		updatedArtists = updatedArtists.filter(x =>
			x.node.data.country.toLowerCase().includes(filters.country.toLowerCase())
		);
	}

	return updatedArtists;
};

const Artists: FC<Props> = ({ artists, events }) => {
	const initialFilters: Filters = {
		name: '',
		location: '',
		artform: '',
		country: '',
	};

	// Mapping out initial values to query params if present
	Object.keys(initialFilters).forEach(key => {
		const value = getParameterByName(key);
		if (value !== null) initialFilters[key] = value;
	});

	const [activeArtists, setActiveArtists]: [IArtistListItem[], any] = useState(
		getFilteredArtists(artists, initialFilters)
	);
	const [fadeOutList, setFadeOutList] = useState(false);
	const allArtforms = [];
	artists.forEach(artist => {
		artist.node.data.artforms.forEach(artform => {
			allArtforms.push(artform.artform);
		});
	});
	const artforms = new Set(allArtforms);
	const allCountries = [];
	artists.forEach(artist => {
		allCountries.push(artist.node.data.country);
	});
	const countries = new Set(allCountries);

	const onFilterUpdate = (filters: Filters) => {
		setFadeOutList(true);

		setTimeout(() => {
			setFadeOutList(false);
			setActiveArtists(getFilteredArtists(artists, filters));
		}, artistFadeOutDuration);
	};

	return (
		<StyledArtists.Wrapper>
			<Container>
				<StyledArtists.FiltersWrapper>
					<ArtistFilters
						onChange={onFilterUpdate}
						events={events}
						artforms={artforms}
						countries={countries}
						initialFilters={initialFilters}
					/>
				</StyledArtists.FiltersWrapper>
				<StyledArtists.ListWrapper fadeOut={fadeOutList} fadeOutDuration={artistFadeOutDuration}>
					<Columns gap="2rem">
						{activeArtists.map(artist => (
							<Column key={artist.node.id} tablet="50%" desktopSmall="33%" desktopLarge="33%" gap="2rem">
								<ArtistTile artist={artist} />
							</Column>
						))}
						{activeArtists.length === 0 && (
							<StyledArtists.NoArtistsFoundMessage>
								<span>No artists found for the given filters</span>
							</StyledArtists.NoArtistsFoundMessage>
						)}
					</Columns>
				</StyledArtists.ListWrapper>
			</Container>
		</StyledArtists.Wrapper>
	);
};

export default Artists;

import React, { FC } from 'react';

interface Props {

}

const SearchIcon: FC<Props> = () => {
	return (
		<svg width="20px" height="25px" viewBox="0 0 20 25" version="1.1">
			<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Frontpage" transform="translate(-669.000000, -684.000000)" fillRule="nonzero">
					<g id="Group" transform="translate(669.000000, 684.000000)">
						<g id="icons8-search" fill="#000000">
							<path d="M7.92425346,0 C3.55244003,0 0,3.63950764 0,8.1184709 C0,12.5974342 3.55244003,16.2369418 7.92425346,16.2369418 C9.65404248,16.2369418 11.2527312,15.6661121 12.5564457,14.6998048 L18.6890022,20.9677419 L20,19.6246126 L13.9402768,13.4014464 C15.1311,11.9799681 15.8485069,10.1350303 15.8485069,8.1184709 C15.8485069,3.63950764 12.2960669,0 7.92425346,0 Z M7.92425346,0.955114223 C11.791697,0.955114223 14.9162418,4.15623924 14.9162418,8.1184709 C14.9162418,12.0807026 11.791697,15.2818276 7.92425346,15.2818276 C4.05680991,15.2818276 0.932265113,12.0807026 0.932265113,8.1184709 C0.932265113,4.15623924 4.05680991,0.955114223 7.92425346,0.955114223 Z" id="Shape"></path>
						</g>
						<g id="icons8-search" transform="translate(0.000000, 4.032258)" fill="#A886DD">
							<path d="M7.92425346,0 C3.55244003,0 0,3.63950764 0,8.1184709 C0,12.5974342 3.55244003,16.2369418 7.92425346,16.2369418 C9.65404248,16.2369418 11.2527312,15.6661121 12.5564457,14.6998048 L18.6890022,20.9677419 L20,19.6246126 L13.9402768,13.4014464 C15.1311,11.9799681 15.8485069,10.1350303 15.8485069,8.1184709 C15.8485069,3.63950764 12.2960669,0 7.92425346,0 Z M7.92425346,0.955114223 C11.791697,0.955114223 14.9162418,4.15623924 14.9162418,8.1184709 C14.9162418,12.0807026 11.791697,15.2818276 7.92425346,15.2818276 C4.05680991,15.2818276 0.932265113,12.0807026 0.932265113,8.1184709 C0.932265113,4.15623924 4.05680991,0.955114223 7.92425346,0.955114223 Z" id="Shape"></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default SearchIcon;

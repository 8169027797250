import React, { FC } from 'react';

interface Props {

}

const DropdownIndicator: FC<Props> = () => {
	return (
		<svg width="31px" height="18px" viewBox="0 0 31 18" version="1.1">
			<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Frontpage" transform="translate(-1196.000000, -683.000000)" fillRule="nonzero">
					<g id="Group-2" transform="translate(1195.000000, 683.000000)">
						<g id="icons8-sort-down" transform="translate(0.695652, 0.000000)">
							<g id="Group-4">
								<path d="M28.153278,1.38461538 L17.1521739,13.224159 L6.1510698,1.38461538 L28.153278,1.38461538 L28.153278,1.38461538 Z M31.3043478,0 L3,0 L17.1521739,15.2307692 L31.3043478,0 Z" id="Shape" fill="#000000"></path>
								<path d="M25.4576258,4.15384615 L14.4565217,15.9933898 L3.45541763,4.15384615 L25.4576258,4.15384615 L25.4576258,4.15384615 Z M28.6086957,2.76923077 L0.304347826,2.76923077 L14.4565217,18 L28.6086957,2.76923077 Z" id="Shape" fill="#A886DD"></path>
								<path d="M28.153278,1.38461538 L17.1521739,13.224159 L6.1510698,1.38461538 L28.153278,1.38461538 L28.153278,1.38461538 Z M31.3043478,0 L3,0 L17.1521739,15.2307692 L31.3043478,0 Z" id="Shape" fill="#000000"></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default DropdownIndicator;

import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import media from '../../constants/media';
import { fluid } from '../../helpers/fluid/fluid';

export const WrapperLink = styled(AniLink)`
	display: block;
	position: relative;
	color: ${({ theme }) => theme.colors.primary};
	padding-top: 90%;
	background-image: url(${({ mobile, defaultimg }) => (mobile || defaultimg)});
	background-size: cover;
	background-position: center top;
	}

	${({ tablet }) => tablet && (
		media.tablet`
		background-image: url(${tablet});
	`
	)}

	${({ desktop }) => desktop && (
		media.desktopLarge`
		background-image: url(${desktop});
	`
	)}

	${({ retina }) => retina && (
		media.retina`
		background-image: url(${retina});
	`
	)}
`;

export const Artform = styled.div`
	font-size: 2.2rem;
	font-weight: 200;
	text-transform: uppercase;
`;

export const Name = styled.h2`
	font-size: ${fluid('font-size', '35px', '40px', '320px', '1439px')};
	font-size: ${fluid('font-size', '20px', '30px', '1440px', '1920px')};
	font-size: ${fluid('font-size', '35px', '40px', '1920px')};
	font-weight: 700;
	line-height: 1.166;
`;

export const Inner = styled.div`
	position: relative;
	padding: 2rem 0 4rem 0;
	color: black;
`;

import theme from '../../../constants/theme';

export const selectStyles = {
	control: () => ({
		display: 'flex',
		justifyContent: 'space-between',
		height: '5.5rem',
		borderBottom: `1px solid ${theme.colors.borderColor}`,
		textTransform: 'uppercase',
		fontFamily: 'Adieu'
	}),
	placeholder: () => ({
		color: '#000000',
		fontWeight: 700,
		fontSize: '16px'
	}),
	indicatorSeparator: () => ({
	}),
	singleValue: () => ({
		color: '#000000',
		fontWeight: 700,
		fontSize: '16px'
	})
};

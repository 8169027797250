import React, { FC, useState } from 'react';
import Select from 'react-select';
import IEventListItem from '../../../interfaces/eventListItem/eventListItem';
import { Input } from '../../base/form/input';
import { selectStyles } from '../../base/form/select';
import DropdownIndicator from '../../icons/dropdownIndicator';
import SearchIcon from '../../icons/search';
import * as StyledArtistFilters from './artistFilters.styles';

export type Filters = {
	name: string;
	location: string;
	artform: string;
	country: string;
};

interface Props {
	onChange: (filters: Filters) => void;
	events: IEventListItem[];
	artforms: Set<string>;
	countries: Set<string>;
	initialFilters: Filters;
}

export type FilterOption = {
	value: string;
	label: string;
	key: string;
};

const ArtistFilters: FC<Props> = ({ onChange, events, artforms, countries, initialFilters }) => {
	const [filters, setFilters]: [Filters, any] = useState(initialFilters);

	const onFilterChange = (option: FilterOption, meta: any) => {
		const updatedFilters = { ...filters };

		if (option === null && meta && meta.action === 'clear') {
			updatedFilters[meta.name] = '';
		} else if (option) {
			updatedFilters[option.key] = option.value;
		}

		setFilters(updatedFilters);
		onChange(updatedFilters);
	};

	const onInputChange = (key: string, event: any) => {
		const updatedFilters = { ...filters };
		updatedFilters[key] = event.target.value;
		setFilters(updatedFilters);
	};

	const onInputKeydown = (key: string, event: any) => {
		if (event.keyCode === 13) onFilterChange({ value: event.target.value, label: '', key: key }, null);
	};

	const eventOptions = events.map(e => ({ value: e.node.uid, label: e.node.data.title.text, key: 'location' }));
	const artformOptions = Array.from(artforms).map(a => ({ value: a, label: a, key: 'artform' }));
	const countryOptions = Array.from(countries).map(a => ({ value: a, label: a, key: 'country' }));

	return (
		<StyledArtistFilters.Wrapper>
			<StyledArtistFilters.FilterWrapper>
				<StyledArtistFilters.SearchWrapper>
					<Input
						type="text"
						placeholder="Name"
						onKeyDown={onInputKeydown.bind(null, 'name')}
						onChange={onInputChange.bind(null, 'name')}
						value={filters.name}
					/>
					<SearchIcon />
				</StyledArtistFilters.SearchWrapper>
			</StyledArtistFilters.FilterWrapper>
			<StyledArtistFilters.FilterWrapper>
				<Select
					name="location"
					placeholder="Event"
					isClearable
					onChange={onFilterChange}
					defaultValue={eventOptions.find(x => x.value.toLowerCase() === filters.location.toLowerCase())}
					styles={selectStyles}
					options={eventOptions}
					components={{ DropdownIndicator: DropdownIndicator }}
				/>
			</StyledArtistFilters.FilterWrapper>
			<StyledArtistFilters.FilterWrapper>
				<Select
					name="artform"
					placeholder="Artform"
					isClearable
					onChange={onFilterChange}
					defaultValue={artformOptions.find(x => x.value.toLowerCase() === filters.artform.toLowerCase())}
					styles={selectStyles}
					options={artformOptions}
					components={{ DropdownIndicator: DropdownIndicator }}
				/>
			</StyledArtistFilters.FilterWrapper>
			<StyledArtistFilters.FilterWrapper>
				<Select
					name="country"
					placeholder="Country"
					isClearable
					onChange={onFilterChange}
					defaultValue={countryOptions.find(x => x.value.toLowerCase() === filters.country.toLowerCase())}
					styles={selectStyles}
					options={countryOptions}
					components={{ DropdownIndicator: DropdownIndicator }}
				/>
			</StyledArtistFilters.FilterWrapper>
		</StyledArtistFilters.Wrapper>
	);
};

export default ArtistFilters;
